
a.rental-link {
    margin: 0px 10px;
}


a.houselink, a.houselink:hover, a.houselink:visited, a.houselink:active {
    color: var(--white-teal);
  }

.houses-h1 {
        color: var(--white);
        font-family: 'Dosis', sans-serif;
        font-size: 32pt;
        font-weight: bold;
}

  .houses-h4 {
    color: var(--white);
    font-family: 'Dosis', sans-serif;
    font-size: 14pt;
    font-weight: bold;
    width: 50%;
    margin: 0 auto;
  }

  .alice-carousel__stage {
    align-items: center;
  }

  .houses-carousel-container {   
        display: block;
        clear: both;
  }

  a.rental-link {
    display: inline-block;
    color: var(--white-teal);
  }


  @media screen and (max-width: 1024px) {

    
}


@media screen and (max-width: 768px) {
  a.rental-link {
    display: inline-block;
  }

  .houses-h4 {
    width: 80%;
    margin: 0 auto;
  }

}
  