:root {
  
    --teal: #5BA199;
    --white: #E5E3E4;
    --light-teal: #95cfc9;
    --purple: #7d39d0;
    --dark-teal: #355A56;
    --gray:  #282c34;

    background-color: var(--gray);
  }
