
/* .infolink-div { */

/* } */

a.infolink {
    color: var(--white-teal);
}



a.infolink:hover {
color: var(--white-teal);
text-decoration: underline;
}

a.infolink:visited {
color: var(--white-teal);
}


/* // The main container element */
/* .Collapsible { */
  /* background-color: $base; */
/* } */


/* //The content within the collaspable area */
.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid 'lightgray';
  border-top: 0;
}
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
  }

    .Collapsible__contentInner:last-child {
      margin-bottom: 0;
    }
  






/* //The link which when clicked opens the collapsable area */
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border: 1px solid var(--light-teal);
  border-radius: 5px;
  padding: 10px;
  background: 'cyan';
  color: white;
}

.Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: ' \02c5';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
  }


.Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg);
}


  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }


.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
  
  
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}



