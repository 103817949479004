:root {
  
  --teal: #5BA199;
  --white: #E5E3E4;
  --light-teal: #95cfc9;
  --white-teal: #defffc;
  --purple: #7d39d0;
  --dark-teal: #355A56;
  --black-teal: #0c1413;
  --gray:  #282c34;
}


.home-container div {
  background-color: var(--dark-teal);
}

.home-h1 {
  color: var(--white);
  font-family: 'Dosis', sans-serif;
  font-size: 32pt;
}

span.cd-num {
  font-size: 42pt;
}


.home-container .carousel-container {
  margin: auto;
  margin-bottom: 10px;
  height: 30%;
}
 


.home-container .slider-img {
  
  height: 500px;
  width: 100%;
  object-fit: cover;
}
 
.home-container .main-text-container {
  border: 10px solid var(--light-teal);
  border-radius: 10px;
  background-color: var(--dark-teal);
  border: 15px solid var(--dark-teal);
  border-radius: 9px;
  
}

.home-container .main-text {
  background-color: var(--light-teal);
  margin-left: auto;
  margin-right: auto;
  width: 90%; 
  text-align: left;
  display: block;
  color: var(--white);
  font-family: 'Dosis', sans-serif;
  font-weight: bold;
  font-size: 18pt;
  border: 2px solid var(--light-teal);
  border-radius: 10px;
  padding: 5px;
}


.home-container .image-banner {
  display: inline-flex;
  align-items: center;
}
 


.home-container p {
  display: block;
  clear: both;
}

.home-container .cta-actual {
  font-weight: bold;
  text-transform: uppercase;
  font-variant-caps: all-petite-caps;
  font-size: 30pt;
}

.home-container .cta-actual a:hover {
  color: var(--light-teal);
}

.home-container .cta-actual a:visited {
  color: var(--white-teal);
}

.home-container #call-to-action-container {
  text-align: center;
  border: 10px solid var(--light-teal);
  border-radius: 10px;
  padding: 10px;
  background-color: var(--light-teal);
  color: var(--dark-teal);
  font-family: Arial, Helvetica, sans-serif;
}

.home-container #call-to-action-text {
  font-size: 20pt;
}

.alice-carousel__stage {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  
}




@media screen and (max-width: 768px) {
  .home-container .main-text {
    font-size: 14pt;
    margin-left: auto;
  margin-right: auto;
  width: 90%;
      }
 
      .home-container .slider-img {
        /* width: 100%; */
        height: 300px;
        object-fit: cover;

      }

       
      .home-container #call-to-action-text {
        font-size: 16pt;
      }
      .home-container .cta-actual {
        font-weight: bold;
        text-transform: uppercase;
        font-variant-caps: all-petite-caps;
        font-size: 20pt;
      }
      .home-container #call-to-action-container {
        text-align: center;
        border: 5px solid var(--light-teal);
        padding: 5px;
        background-color: var(--light-teal);
      }



      /* .countdown-div {
        width: 95%;
        margin: 10px auto;
        border: 3px solid var(--light-teal);
        border-radius: 10px;
        padding: 10px 0px;
        
      }
      
      .countdown {
        color: var(--white-teal);
        font-size: 9pt;
      }
      
      .countdown-title {
        color: var(--white-teal);
      } */
      
      
      

      
      span.cd-until {
        font-size: 14pt;
      }
      
      .disclaimer {
        font-size: x-small;
      }
}




