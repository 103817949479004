:root {
  
  --teal: #5BA199;
  --white: #E5E3E4;
  --light-teal: #95cfc9;
  --purple: #7d39d0;
  --dark-teal: #355A56;
  --gray:  #282c34;
}
  
  
  .info-container div {
    background-color: var(--dark-teal);
  }

  .info-container .info-h1 {
    color: var(--white);
    font-family: 'Dosis', sans-serif;
    font-size: 32pt;
  }
    
  .info-container .carousel-container {
    margin: auto;
    margin-bottom: 10px;
    height: 30%;
  }

  .invite-map {
    width: 90%;
  }
  
  .info-container .slider-img {
    height: 500px;
    width: 100%;
    object-fit: cover;
  }
  
  .info-container .image-banner {
    display: inline-flex;
    align-items: center;
  }
  
  .info-container p {
    display: block;
    clear: both;
  }
  
  .info-container .info-item {
    display: block;
    flex-wrap: wrap;
    align-items: center;
    flex-basis: 90%;
    color: var(--white);
    font-family: 'Dosis', sans-serif;
    font-weight: bold;
    font-size: 18pt;
    
  }

  .info-container .info-footer {
    display: block;
    flex-wrap: wrap;
    align-items: center;
    flex-basis: 90%;
    color: var(--white);
    font-family: 'Dosis', sans-serif;
    font-weight: bold;
    font-size: 18pt;
  }
  
  .info-container .info-item span {
    padding: 10px;
  }
  
  .info-container .info-img {
    max-height: 200px;
    padding: 10px;
  }
  
  .info-container .info-link {
    display:block;
    font-size: 16pt;
  }

  a.info-link {
    color: var(--white-teal);
  }

  span.info-link {
    margin-top: 10px;
  }

  a.info-link:hover {
    color: var(--white-teal);
    text-decoration: underline;
  }

  a.info-link:visited {
    color: var(--white-teal);
  }



  a.lodging-link, a.lodging-link:hover, a.lodging-link:visited, a.lodging-link:active {
    display: block;
    color: var(--white-teal);
  }

  .reserved-block a.lodging-link,
  .reserved-block a.lodging-link:hover,
  .reserved-block a.lodging-link:visited,
  .reserved-block a.lodging-link:active {
    display: block;
    color: var(--teal);
  }

  a.directions-link, a.directions-link:hover, a.directions-link:visited, a.directions-link:active {
    display: inline-block;
    color: var(--white-teal);
    padding-left: 10px;
    padding-right: 10px;
  }

  .reserved-block h3 {
    font-weight: bold;
  }

  .info-item h2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .info-item h3 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .info-item h4 {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .info-container .info-text-container {
    background-color: var(--dark-teal);
    border: 10px solid var(--dark-teal);
    border-radius: 9px;
  }
  
  .info-container .info-text {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    text-align: center;
    display: block;
    color: var(--white);
    font-family: 'Dosis', sans-serif;
    font-weight: bold;
    font-size: 18pt;
    background-color: var(--light-teal);
    border: 2px solid var(--light-teal);
    border-radius: 9px;
    padding: 5px;
  }

  .info-container .lodging-text {
    width: 40%;
    margin: 20px auto;
    text-align: left;
    text-indent: 5%;
  }

  .alice-carousel__next-btn, .alice-carousel__prev-btn {
    width: 5%;
  }

  .alice-carousel__next-btn-item, .alice-carousel__prev-btn-item {
    color: var(--white);
    /* width: 5%; */
    margin: auto;
    text-align: center;

  }

  div.reserved-block {
    padding: 20px;
    background-color: var(--white-teal);
    color: var(--dark-teal);
    width: 50%;
    margin: 0 auto;
    border: 3px solid var(--white-teal);
    border-radius: 10px;

  }

  div.reserved-block h3 {
    margin-top: 0;
  }

  span.disc-code {
    font-size: 24pt;
    font-weight: 1000;
  }

  .block-disclaimer {
    color: var(--white-teal);
    font-size: 12pt;
  }

  

  hr {
    
    background-color: var(--light-teal);
    width: 80%
  }

  @media screen and (max-width: 1024px) {
    .info-container .info-text {
      width: 90%;
    }
    .info-container .lodging-text {
      width: 65%;
      text-indent: 10%;
    }
    .invite-map {
      width: 60%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .info-container .info-item {
      font-size: 14pt;
    }
    .info-container .slider-img {
      /* width: 90%; */
      height: 300px;
    }
    .info-container .info-text {
      width: 90%;
    }
    .alice-carousel__next-btn, .alice-carousel__prev-btn {
      width: 15%;
    }
    
    .info-container .lodging-text {
      width: 80%;
      text-indent: 10%;
    }

    .invite-map {
      width: 90%;
    }

    span.alert {
      font-size: 14pt;
      border: none;
      border-radius: 0;
    }
  
    span.disc-code {
      font-size: 24pt;
      font-weight: 1000;
    }
  
    .block-disclaimer {
      color: var(--white-teal);
      font-size: 10pt;
    }

    div.reserved-block {
      width: 90%;
    }
  }
  
  