:root {
  
  --teal: #5BA199;
  --white: #E5E3E4;
  --light-teal: #95cfc9;
  --purple: #7d39d0;
  --dark-teal: #355A56;
  --gray:  #282c34;
}


.cabins-container div {
  background-color: var(--dark-teal);
}

.cabin-h1 {
  color: var(--white);
  font-family: 'Dosis', sans-serif;
  font-size: 32pt;
}

.cabins-container .carousel-container {
  margin: auto;
  margin-bottom: 10px;
  height: 50%;
}

.cabins-container .slider-img {
  height: 500px;
  width: 100%;
  object-fit: cover;
}


.cabins-container .main-text-container {
  background-color: var(--dark-teal);
  border: 15px solid var(--dark-teal);
  border-radius: 9px;
  
}

.cabins-container .main-text {
  background-color: var(--light-teal);
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  text-align: left;
  display: block;
  color: var(--white);
  font-family: 'Dosis', sans-serif;
  font-weight: bold;
  font-size: 18pt;
  border: 2px solid var(--light-teal);
  border-radius: 10px;
  padding: 5px;
}

.alice-carousel__stage {
  align-items: center;
}



.cabins-container p {
  display: block;
  clear: both;
}

.cabin-links {
  display: flex;
  flex-wrap: wrap;
  clear: both;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray);
  padding: 5%;
}

.cabin-link {
  text-decoration: none;
        padding: 5px 10px;
        color: var(--white);
        margin-right: 10px;
        background-image: linear-gradient(270deg, var(--light-teal) 0%, var(--light-teal) 100%);
        background-repeat: no-repeat;
        background-size: 100% 0.2em;
        background-position: bottom;
        transition: all 0.25s ease-in;
        font-size: 1.2rem;
        font-family: 'Dosis', sans-serif;
        margin-bottom: 20px;
        margin-top: 10px;
        font-weight: bold;
}

.cabin-link:hover {
  background-size: 100% 100%;
  color: var(--white);
}

.cabins-container .cta-actual a:hover {
  color: var(--light-teal);
}

.cabins-container .cta-actual a:visited {
  color: var(--white-teal);
}

.cabins-container a:hover {
  color: var(--white-teal);
}

.cabins-container a:visited {
  color: var(--white-teal);
}

.cabins-container .cta-actual {
  font-weight: bold;
  text-transform: uppercase;
  font-variant-caps: all-petite-caps;
  font-size: 26pt;
}

.cabins-container .call-to-action-container {
  text-align: center;
  border: 10px solid var(--light-teal);
  border-radius: 10px;
  padding: 10px;
  background-color: var(--light-teal);
  color: var(--dark-teal);
  font-family: Arial, Helvetica, sans-serif;
}

.cabins-container .call-to-action-text {
  font-size: 20pt;
}

.cabins-container article {
  background-color: var(--dark-teal);
}

.cabins-container .contact-info {
  margin-left: auto;
  margin-right: auto;
  background-color: var(--light-teal);
  width: 25%;
  padding: 10px;
  color: var(--dark-teal);
  border-color: var(--light-teal);
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  
}

.cabins-container .contact-info .contact-name  {
  color: var(--white-teal);
  font-size: 22pt;
}

/* Cabin Header */

.cabin-header-container h3 {
  color: var(--white);
  font-family: 'Shadows Into Light', sans-serif;
  background-color: none;
  
}

.cabin-header-container .page-title {
  color: var(--white);
  background-color: var(--dark-teal);
  border: outset 5px var(--dark-teal);

}


.cabin-header-container header {
  padding: 15px 0 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--teal);
  box-shadow: 0 5px 35px rgba(150, 200, 205, 0.5);
  }

.cabin-header-container header h1 {
  color: var(--white);
  padding: 10px 8% 10px 8%;
  margin: 0;
  font-size: 5vw;
  line-height: 1;
  font-family: 'Cookie', cursive;
}

.cabin-header-container nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: var(--teal);
}

  .cabin-header-container nav a {
      text-decoration: none;
      padding: 5px 10px;
      color: var(--white);
      margin-right: 10px;
      background-image: linear-gradient(270deg, var(--light-teal) 0%, var(--light-teal) 100%);
      background-repeat: no-repeat;
      background-size: 100% 0.2em;
      background-position: bottom;
      transition: all 0.25s ease-in;
      font-size: 1.2rem;
      font-family: 'Dosis', sans-serif;
      margin-top: 40px;
      font-weight: bold;
      
  }

  .cabin-header-container nav a:hover {
      background-size: 100% 100%;
      color: var(--white);
  }


div.alert-box {
  background-color: red;
  font-size: small;
  width: 75%;
  margin: auto;
  padding: 10px;
  font-family:'Courier New', Courier, monospace;
  font-weight: bold;
  color: #FFF;
  border-radius: 5px;
}



@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .cabin-link {
    font-size: 0.8rem;
    background-size: 100% 0.1em;
  }
  .cabins-container .main-text {
      font-size: 14pt;
  }
  .cabins-container .slider-img {
    /* width: 50%; */
    /* height: 200px; */
    object-fit: fit;
  }
  .cabins-container .call-to-action-text {
    font-size: 16pt;
  }
  .cabins-container .cta-actual {
    font-weight: bold;
    text-transform: uppercase;
    font-variant-caps: all-petite-caps;
    font-size: 20pt;
  }
  .cabins-container .call-to-action-container {
    text-align: center;
    border: 5px solid var(--light-teal);
    padding: 5px;
    background-color: var(--light-teal);
  }

  .cabin-header-container header h1 {
    font-size: 8vw;
  }

  .cabin-header-container h3 {
    background-color: none;
    margin-top: 2%;
    margin-left: 10%;
  }

  .cabin-header-container nav a {
    font-size: 14pt;
    background-size: 100% 0.1em;
  }

  .cabin-header-container nav {
    margin-right: 10%;
  }

}


@media screen and (max-width: 550px) {
  .cabin-header-container header {
    margin: auto;
    display: block;
    
  }
  
  .cabin-header-container .page-title {
    font-size: 26pt;
  }

  .cabin-header-container h3 {
    width: 100%;
    font-size: 18pt;
    display: block;
    margin-top: 10px;
  }

  .cabin-header-container nav {
    /* change margin-left to 30% or something to center     */
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;   
    display: block;
    
  }

  .cabins-container .slider-img {
    height: 300px;
  }

  .cabin-header-container nav a {
    font-size: 12pt;
    text-decoration: none;
    padding: 5px 10px;
    color: var(--white);
    margin-right: 10px;
    margin-top: 0px;
    background-image: linear-gradient(
      270deg,
      var(--light-teal) 0%,
      var(--light-teal) 100%
    );
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: bottom;
    transition: all 0.25s ease-in;
    /* font-size: 1.2rem; */
    font-family: "Dosis", sans-serif;
    font-weight: bold;
  }

  .cabins-container .contact-info {
    font-size: 12pt;
    width: 95%;

    
  }
  
  .cabins-container .contact-info .contact-name  {
    font-size: 16pt;
  }


}