a.houselink {
    display: inline;
    
}

span.house-name {
    padding-left: 20px;
    font-family: 'Dosis', sans-serif;
    font-size: 18pt;
    font-weight: bold;
    display: inline;

}

div.house-img {
    display: block;
    /* vertical-align: middle; */
    
}


div.houselink-div {
    margin: 10px auto;
    padding: 10px;
    border: 2px solid var(--light-teal);
    border-radius: 10px;
    width: 50%;
}

div.houselink-div img {
    width: 90%;
    height: 300px;
    /* height: 50%; */
    object-fit: cover;
}


.details {
    display: block;
    /* vertical-align: center; */
    padding-left: 50px;
}

.details span {
    font-family: 'Courier New', Courier, monospace;
    padding: 0px 20px;
    display: block;
    font-weight: bold;
    font-size: 12pt;
    color: var(--white);
}

.house-loc {
    font-size: medium;
    font-weight: bold;
    color: var(--white);
    font-family: 'Dosis', sans-serif;
    display: inline;
}

.alice-carousel__dots::before {
    padding-bottom: 0;
}





@media screen and (max-width: 1024px) {

    div.houselink-div {
        width: 90%;
    }

    div.houselink-div img {
        width: 90%;
        height: 150px;
    }

    .house-loc {
        display: block;
        padding-left: 20px;
    }

    span.house-name {
        padding-left: 0;
    }
}


@media screen and (max-width: 768px) {

    div.houselink-div {
        width: 90%;
    }

    div.houselink-div img {
        width: 90%;
        height: 150px;
    }

    div.house-img {
        display: block;
    }


    .details {
        display: block;
        padding-left: 0;
    }

    .house-loc {
        display: block;
        padding-left: 0px;
    }

    .alice-carousel__dots {
        display: none;
    }
}