:root {
  
    --teal: #5BA199;
    --white: #E5E3E4;
    --light-teal: #95cfc9;
    --purple: #7d39d0;
    --dark-teal: #355A56;
    --gray:  #282c34;
  }
  
  .email-modal-content {
      background-color: var(--light-teal);
      border-color: var(--light-teal);
      border-radius: 10px;
      color: var(--dark-teal);
      font-family: 'Dosis', sans-serif;
      font-weight: bold;
      font-size: 18pt;
      width: 50%;
      height: 50%;
      padding: 20px;
      
  }
  
  .email-modal-msg {
      font-size: 16pt;
      text-align: center;
      height: 90%;
  }
  
  .email-modal-footer button {
      
      display: block;
      margin: auto;
      height: 10%;
      font-family: none;
  }
  
  
  
  .rsvp-form-container {
    background-color: var(--dark-teal);
    color: var(--white);
    padding: 10px;
  }


  /* 
  
      // 216, 1012  (from left)
    // 265, 1394 (from top)
    // 187, 1316 (from bottom)
  
  */
  
  .rsvp-form {
    color: var(--dark-teal);
    width: 40%;
    margin: 0 auto;
    background-color: white;
    border: 120px solid var(--light-teal);
    /* border-radius: 10px; */
    border-image: url(../../assets/images/invites/Invitation\ RSVP\ Front.png) 200 round;
    border-image-slice: 265 213 187 216;
    

    padding: 40px 20px;

    min-width: 350px;

  }
  
  .rsvp-form-text {
    padding: 20px auto;
    color: var(--dark-teal);
    font-size: 20pt;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .rsvp-label {
    padding-top: 25px;
  }
  

  .rsvp-footer {
    padding-top: 40px;
  }


  .std-form-text {
    color: var(--white) !important;
  }
  
  
  
  .mainBtn {
      position: relative;
      justify-items: center;
      padding: 0.8rem 2rem;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      color: #fff;
      background-color: green;
      border: none;
      border-radius: 1rem;
      cursor: pointer;
    }
    
    .mainBtn:hover {
      background-color: lightgreen;
    }
    
    .mainBtn:active {
      background-color: blue;
    }
    
    .closeBtn:active {
      background-color: red;
    }
    
  
  
  
  
  .form-content {
    width: 40%;
    align-items: center;
    text-align: center;
    display: block;
  }
  
  
  .form-input {
    text-align: left;
  }
  
  #rsvp-total {
    width: 50%;
  }

  .form-radios {
    font-weight:lighter;
  }
  
  .form-rad-btn .form-radios {
      font-weight:bold;
    }
  
  /* .form-footer {} */
  
  
  
   
    
  @media screen and (max-width: 932px) {

    .rsvp-form {
      width: 80%;
      border-image-width: 30px;
      border-width: 30px;
      /* border-image-outset: -10; */
      height: 100%;
    }

    .rsvp-form-text {
      padding: 20px auto;
      font-size: 16pt;
      width: 90%;
      margin: 0 auto;
    }

    .p-inputnumber-input {
      width: 50%;
      text-align: center;
    }
  
    .rsvp-label {
      padding-top: 10px;
      font-size: 10pt;
    }
    
  
    .rsvp-footer {
      padding-top: 20px;
    }



    input,
    input[type="submit"],
    input[type="cancel"],
    input[type="button"],
    input[type="text"],
    a,
    div,
    .p-button,
    .p-selectbutton,
    .p-inputnumber-button,
    .rsvp-control {
  
      appearance: none !important;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
    }
  
    

  }
    