:root {
  
    --teal: #5BA199;
    --white: #E5E3E4;
    --light-teal: #95cfc9;
    --purple: #7d39d0;
    --dark-teal: #355A56;
    --gray:  #282c34;
  }

    .registry-container div {
      background-color: var(--dark-teal);
      margin: auto; 
    }

.registry-h1 {
    color: var(--white);
    font-family: 'Dosis', sans-serif;
    font-size: 32pt;
  }

  #registry-text {
    display: block;
    color: var(--white);
    font-family: 'Dosis', sans-serif;
    font-size: 18pt;
  }

  .registry-fill {
    background-color: var(--dark-teal);
  }