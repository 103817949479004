







.xform-content {
  color: #FFFFFF;
  padding: 10px;
  display:flex;
  flex-basis: 100%;
}

.xform-item {
  display: inline-block;
  flex-basis: 20%;
  flex-grow: 1;
}

.xform-content input {
  color: #aaaaaa;

}

.xform-content input:focus {
  color: #000000;
}

.input-label {
  font-variant-caps: all-small-caps;
  margin: 0px 5px 0px;
}

.submitBtn {
  margin: 5px 20px;
 
}