:root {
  --teal: #5ba199;
  --white: #e5e3e4;
  --light-teal: #95cfc9;
  --purple: #7d39d0;
  --dark-teal: #355a56;
  --gray: #282c34;

  background-color: var(--gray);
}

.header-container h3 {
  color: var(--white);
  font-family: "Shadows Into Light", sans-serif;
  background-color: var(--teal);
}

.header-container .page-title {
  color: var(--white);
  background-color: var(--dark-teal);
  border: outset 5px var(--dark-teal);
}

.header-container header {
  padding: 15px 0 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  background-color: var(--teal);
  box-shadow: 0 5px 35px rgba(150, 200, 205, 0.5);
}

.header-container header h1 {
  color: var(--white);
  padding: 10px 8% 10px 8%;
  margin: 0;
  font-size: 5vw;
  line-height: 1;
  font-family: "Cookie", cursive;
}

.header-container nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: none;
  margin-top: 100px;
}

.header-container nav a {
  text-decoration: none;
  padding: 5px 10px;
  color: var(--white);
  margin-right: 10px;
  background-image: linear-gradient(
    270deg,
    var(--light-teal) 0%,
    var(--light-teal) 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: bottom;
  transition: all 0.25s ease-in;
  font-size: 1.4rem;
  font-family: "Dosis", sans-serif;
  font-weight: bold;
}

.header-container nav a:hover {
  background-size: 100% 100%;
  color: var(--white);
}

@media screen and (min-device-width: 820px) and (max-device-width: 1180px) {
  .header-container header h1 {
    font-size: 8vw;
    
  }


  .header-container {
    height: 270px;
  }

  .header-container h3 {
    width: 100%;
    background-color: var(--teal);
    margin-top: 5%;
    margin-left: 40px;
    margin-bottom: 0px;
  }

  .header-container nav {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
  }

  .header-container nav a {
    font-size: 14pt;
    background-size: 100% 0.1em;
    margin-top: 0px;
    padding: 0% 5px 2% 5px;
    margin-left: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .header-container header h1 {
    font-size: 8vw;
  }

  .header-container h3 {
    width: 100%;
    margin-top: 20px;
  }

  .header-container nav {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
    position: relative;
  }

  .header-container nav a {
    font-size: 14pt;
  }
}


 @media screen and (max-width: 550px) {
  .header-container header h1 {
    font-size: 8vw;
    
  }

  .header-container header {
    display: block;
  }

  .header-container h3 {
    margin-top: 10px;
    width: 100%;
  }
  .header-container nav {
    display: block;
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 0;    
  }

  .header-container nav a {
    text-decoration: none;
    padding: 5px 10px;
    color: var(--white);
    margin-right: 10px;
    background-image: linear-gradient(
      270deg,
      var(--light-teal) 0%,
      var(--light-teal) 100%
    );
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: bottom;
    transition: all 0.25s ease-in;
    font-size: 1.2rem;
    font-family: "Dosis", sans-serif;
    font-weight: bold;
  }

  .header-container nav a:hover {
    background-size: 100% 100%;
    color: var(--white);
  }
} 



